import { Link } from 'gatsby';
import React from 'react';
import Layout from '../components/Layout';

const Thankyou = (props) => {
  return (
    <Layout fullMenu location={props.location} title="Thank you">
      <article id="main">
        <header name="THANK YOU">
          <h2>Thank you</h2>
          <p>Cảm Ơn</p>
        </header>
        <section className="wrapper style5 rsvp">
          <iframe
            title="hidden_iframe"
            name="hidden_iframe"
            id="hidden_iframe"
            style={{ display: 'none' }}
          ></iframe>

          <div className="inner">
            <div className="thanks">
              <h2>Thank you for your response!</h2>
              <br />
              <p>
                Please see the <Link to="/events">Schedule of Events</Link> for
                more details of the wedding day.
              </p>
              <p>
                Any questions? Please visit the <Link to="/faq">FAQ</Link> page to see if your question has already been answered!
              </p>
            </div>
          </div>
        </section>
      </article>
    </Layout>
  );
};

export default Thankyou;
